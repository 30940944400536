// Here you can add other styles
.dropdown-height {
  height: 400px;
  overflow-y: auto;
}
.WON {
  color: #28a745;
  font-weight: bold;
}
.LEADING {
  color: #17a2b8;
  font-weight: bold;
}
.LOST {
  color: #dc3545;
  font-weight: bold;
}
.TRAILING {
  color: #dc3545;
  font-weight: bold;
}
.NA {
  color: #6c757d;
  font-weight: bold;
}
